/*
 * Welcome to your app's main JavaScript file!
 *
 * We recommend including the built version of this JavaScript file
 * (and its CSS file) in your base layout (base.html.twig).
 */

// any CSS you import will output into a single css file (app.css in this case)
import "~/module/logJsErrors";
import "~/module/gdpr";
import "~/function/goBackOnClick";
import $ from "jquery";
import "bootstrap/js/src/collapse";

import "bootstrap/dist/css/bootstrap.css";
import "~/styles/style.css";

// start the Stimulus application
// import './bootstrap';
global.$ = global.jQuery = $;
